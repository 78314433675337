import { useEffect } from "react";
function useNavigationEffect() {
    useEffect(function () {
        const title = "Burgverein Hohenfels e.V.";
        const item = document.querySelectorAll(".navbar-brand");
        if(item.length > 0 && item[0]) {
            item[0].classList.remove("active");
        }
        
        const isRuinHistorySite = document.getElementById("ruin-history") !== null;
        const isRuinPositionSite = document.getElementById("ruin-position") !== null;
        const isRuinGallerySite = document.getElementById("ruin-gallery") !== null;
        const isRuinVisualizationSite = document.getElementById("ruin-visualization") !== null;

        const isGuildHistorySite = document.getElementById("guild-history") !== null;
        const isChairmanSite = document.getElementById("association-chairman") !== null;

        const isStatuteSite = document.getElementById("statute") !== null;

        const isTasksSite = document.getElementById("tasks") !== null;
        //const isConstructionSite = document.getElementById("construction-site") !== null;

        const navbar = document.getElementById("navbar-nav");
        if (navbar && navbar.classList.contains("show")) {
            navbar.classList.remove("show")
        }
        else if (isRuinHistorySite) {
            document.title = title + " | Geschichte der Burgruine";
        }
        else if (isRuinGallerySite) {
            document.title = title + " | Bilder der Ruine";
        }
        else if (isRuinPositionSite) {
            document.title = title + " | Lage der Ruine";
        }
        else if (isRuinVisualizationSite) {
            document.title = title + " | Rekonstruktions-Modelle";
        }
        else if (isTasksSite) {
            document.title = title + " | Aufgaben des Burgvereins Hohenfels e.V.";
        }
        else if(isGuildHistorySite) {
            document.title = title + " | Geschichte des Vereins";
        }
        else if(isChairmanSite) {
            document.title = title + " | Der Vorstand";
        }
        else if(isStatuteSite) {
            document.title = title + " | Satzung";
        }
        //logo
        else if(document.getElementById("association-logo") !== null) {
            document.title = title + " | Vereinslogo";
        }
        else if(document.getElementById("project-cellar") !== null) {
            document.title = title + " | Projekt »Kellergewölbe«";
        }
        else if(document.getElementById("project-modell") !== null) {
            document.title = title + " | Projekt »Modellerstellung«";
        }
        else if(document.getElementById("project-donationboard") !== null) {
            document.title = title + " | Projekt »Spendentafel«";
        }

        //mitglied werden 
        else if(document.getElementById("become-member") !== null) {
            document.title = title + " | Mitglied werden";
        }
        //imprint
        else if(document.getElementById("imprint") !== null) {
            document.title = title + " | Impressum";
        }
        //datenschutz
        else if(document.getElementById("dataprotection") !== null) {
            document.title = title + " | Datenschutzerklärung";
        }
        else if(document.getElementById("midsummernight") !== null) {
            document.title = title + " | Mittsommernacht";
        }
        else if(document.getElementById("guestbook") !== null) {
            document.title = title + " | Gästebuch";
        }
        else {
            document.title = title;
        }
        
        const root = document.getElementById("root");
        if (root) {
            root.classList.remove("home-page");
        }

    }, []);
}

export default useNavigationEffect;