import React from "react";

import { Container } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

function CellerProject() {
  useNavigationEffect();
  return (
    <div id="project-cellar" className="webpage">
      <Container className="main-container">
        <CardWithImageComponent
          id="keller-skizze"
          className="card-with-image"
          image="../../images/general_images/skizze-keller.png"
          header="Projekt: Kellergewölbe"
          content={
            <div>
              <small>
                Rekonstruktionsskizze der Hohenfels Ostburg. Rot markiert ist
                die Lage des Kellergewölbes, das sich unterhalb eines größeren
                Gebäudes befand.
              </small>
              <br />
              <br />
              Das Kellergewölbe auf der Ostburg der Hohenfels Anlagen wurde ca.
              1250 erbaut. Somit ist es seit 775 Jahren im Originalzustand. Was
              diese Mauern wohl schon alles gesehen haben mögen? Generationen
              von Dautphetalern sind mit dem Kellergewölbe seit Kindheit an in
              Berührung gekommen. In den letzten 10 Jahren hat dieser noch
              erhaltene Teil der Hohenfels Anlagen, mit einer einst weit über
              die Grenzen Dautphetals hinausgehenden Bedeutung, aber „Federn
              gelassen“. Nachdem ein Mittelteil der Gewölbedecke einstürzte, ist
              der Zustand des noch verbliebenen Gewölbes zunehmend kritisch!
              <br />
              Wir finden, dass das historische Kellergewölbe durch sein Alter,
              seine Lage inmitten einer bedeutenden regionalen,
              mittelalterlichen Feudalanlage, seine phantasieanregende Wirkung
              auf den Besucher und als Dokument mittelalterlicher Baukunst mehr
              Zuwendung und Aufmerksamkeit verdient hat.
              <br />
              Ohne Instandsetzung der Gewölbedecke, als ein sich selbst
              stabilisierender Mauerbogen, wird die Zeit ihr Übriges dazu tun,
              um in wenigen Jahren nur noch die Grundmauern des Gewölbes
              erkennen zu lassen. <br />
              Wir als Burgverein Hohenfels e.V. können und wollen das nicht
              zulassen und werben daher um Unterstützung jeglicher Art, sei es
              durch Mitgliedschaft, Spenden, oder die Zusage mit anzupacken,
              wenn helfende Hände gebraucht werden. Vor allem die
              aufzubringenden Gelder werden hier eine Schlüsselrolle spielen, um
              das Projekt „Kellergewölbe“ realisieren zu können.
              <br />
              Ziel unseres „Projekt Kellergewölbe“ ist daher die komplette
              Instandsetzung des in sich tragenden Kellergewölbes, also die
              Wiederherstellung des Originalzustandes.
              <br />
              Wir arbeiten derzeit auch an einem Nutzungskonzept des
              Hohenfelses, in welchem ein restauriertes Kellergewölbe einen
              entscheidenden Anteil haben wird.
            </div>
          }
        />
        <CardWithImageComponent
          id="grabung-2002"
          className="card-with-image"
          image="../../images/general_images/grabung-2.jpg"
          content={
            <div>
              Grabungsdokumentation aus dem Jahr 2002; über 2 Meter Höhe ab dem
              historischen Gewölbeboden gaben einen imposanten Eindruck vom
              ursprünglichen Zustand. Im Rahmen des Nutzungskonzeptes soll das
              Kellergewölbe der Hohenfels Ostburg wieder begehbar gemacht
              werden.
            </div>
          }
        />
        <CardWithImageComponent
          id="grabung-2002"
          className="card-with-image"
          image="../../images/general_images/grabung.jpg"
          content={
            <div>
              Grabungsdokumentation aus dem Jahr 2002; Freilegung eines
              Gewölbeschadens, entstanden durch Raubgrabung des 20.Jh. Daneben
              nicht klar zuzuordnende Mauerformation lässt Spielraum für
              Interpretationen.
            </div>
          }
        />

        <CardWithImageComponent
          id="current-picture"
          className="card-with-image"
          image="../../images/general_images/ruine-einsturz.jpg"
          content={
            <div>
              Einblick in den derzeitigen Zustand des Kellergewölbes: ein
              Mittelteil der Gewölbedecke ist eingestürzt und muss dringend
              saniert werden.
            </div>
          }
        />
      </Container>
    </div>
  );
}

export default CellerProject;
