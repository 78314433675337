import React from "react";

import { Container, Card, Row, Col } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect";

function ModelsProject() {
  useNavigationEffect();
  return (
    <div id="project-modell" className="webpage">
      <Container className="main-container">
        <Card className="card-web">
          <Card.Header>Modellerstellung</Card.Header>
          {/** <Card.Img variant="center" src="../../images/general_images/modell.png" />*/}
          <Card.Body>
            <Card.Text>
              Zu beiden Anlagen (Ost und Westburg) soll ein Modell entstehen,{" "}
              <br />
              welches den Zeitgenössischen Zustand des Jahres 1250 abbilden
              soll. <br />
              Über einen öffentlicher Wettbewerb dafür wird derzeit noch
              diskutiert.
              <br />
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default ModelsProject;
