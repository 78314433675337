import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import useNavigationEffect from "../effects/NavigationEffect.js";
import CardWithImageComponent from "../components/CardWithImageComponent.js";
import Beitrittserklaerung from "../files/BVH_Beitrittserklaerung.pdf";

function BecomeMember() {
  useNavigationEffect();
  return (
    <div id="become-member" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Mitglied werden</Card.Header>
          <Card.Body>
            Wenn Du Dich irgendwo in den folgenden Punkten wieder findest
            solltest Du Dir die Frage stellen, ob Du uns durch Deine
            Mitgliedschaft unterstützen könntest: <br /> <br />
            <ul>
              <li>Du hast Interesse an der Zeit des Hochmittelalters</li>
              <li>
                Du interessierst Dich für regionale Geschichte (in Dautphetal,
                Biedenkopf, Hinterland)
              </li>
              <li>Du möchtest regionale Kultur und Geschichte unterstützen</li>
              <li> Du bist in Dautphetal oder Biedenkopf verwurzelt</li>
              <li>
                {" "}
                Du möchtest ein 775 Jahre altes Kellergewölbe vor dem Einsturz
                bewahren helfen
              </li>
              <li> Du findest die Indiana Jones Filme prima! 😉</li>
              <li> Du hast Entdeckergeist und Phantasie</li>
              <li> Du möchtest dich ehrenamtlich engagieren</li>
              <li>
                {" "}
                Du möchtest dich nicht ehrenamtlich engagieren aber durch die
                Mitgliedschaft an sich deinen Beitrag leisten
              </li>
            </ul>
            <br />
            Um die Ziele des Vereins zu erreichen, brauchen wir mehr von Euch!{" "}
            <br />
            In den Mitgliedszahlen spiegelt sich zum Teil auch das öffentliche
            Interesse wider. <br />
            Auch eine rein passive Mitgliedschaft freut uns (siehe Auswahlfeld
            im Antrag) und hilft dem Vorstand die zuständigen Einrichtungen von
            uns und unseren Vorhaben zu überzeugen! <br />
            Der Beitrag liegt mit 12,50€ pro Jahr im überschaubaren Bereich.{" "}
            <br />
            Kinder, Schüler und Studenten sind zudem von der Beitragspflicht
            befreit.
            <br />
            Wenn Du Dich mit unseren Aufgaben und Zielsetzung identifizieren
            kannst, werde Mitglied! <br />
            Gemeinsam sind wir stärker als allein!
            <br />
            Entgegen dem Eindruck, dass Vereine nicht gerade im Trend der Zeit
            sind, können wir einen jährlichen Mitgliederzuwachs von etwa 25%
            verzeichnen. <br />
            Wir sind sehr dankbar für das uns entgegengebrachte Vertrauen und
            sind gut aufgestellt und organisiert, um diesem Gerecht zu werden.
            <br />
            Wir sind in persönlichen Gesprächen immer wieder überrascht darüber,
            Wieviele unsere Sache gut finden. <br />
            Eine Resonanz ist gerade im Ehrenamt wichtig, um motiviert zu
            bleiben und um gemeinsam neue Ideen zu entwickeln, letztendlich auch
            um zu wissen was ankommt.
            <br />
          </Card.Body>
        </Card>
        <CardWithImageComponent
          image="images/general_images/beitritt.png"
          header="Mitglied werden"
          className="card-with-image"
          id="become-member-card"
          content={
            <div>
              <a
                style={{ display: "flex", justifyContent: "center" }}
                href={Beitrittserklaerung}
                download="BVH_Beitrittserklaerung"
                target="_blank"
              >
                <Button className="btn btn-primary">Zum Antrag</Button>
              </a>
            </div>
          }
        />
      </Container>
    </div>
  );
}
export default BecomeMember;
