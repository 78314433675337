import React from "react";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../effects/NavigationEffect";
function DataProtection() {
  const _contents_preview = (
    <div className="card-body">
      <ul className="index">
        <li>
          <a className="index-link" href="#preamble">
            Präambel
          </a>
        </li>
        <li>
          <a className="index-link" href="#person_in_charge">
            Verantwortlicher
          </a>
        </li>
        <li>
          <a className="index-link" href="#overview_dataprocessing">
            Übersicht der Verarbeitungen
          </a>
        </li>
        <li>
          <a className="index-link" href="#relevant_legal_bases">
            Maßgebliche Rechtsgrundlagen
          </a>
        </li>
        <li>
          <a className="index-link" href="#security_measures">
            Sicherheitsmaßnahmen
          </a>
        </li>
        <li>
          <a className="index-link" href="#transmission_of_personal_data">
            Übermittlung von personenbezogenen Daten
          </a>
        </li>
        <li>
          <a className="index-link" href="#rights_persons_concerned">
            Rechte der betroffenen Personen
          </a>
        </li>
        <li>
          <a className="index-link" href="#use_of_cookies">
            Einsatz von Cookies
          </a>
        </li>
        <li>
          <a
            className="index-link"
            href="#provision_onlineoffer_and_webhosting"
          >
            Bereitstellung des Onlineangebotes und Webhosting
          </a>
        </li>
        <li>
          <a className="index-link" href="#contact_and_inquiry_management">
            Kontakt- und Anfragenverwaltung
          </a>
        </li>
        <li>
          <a className="index-link" href="#social_media">
            Präsenzen in sozialen Netzwerken &#040;Social Media&#041;
          </a>
        </li>
        <li>
          <a className="index-link" href="#plugins">
            Plugins und eingebettete Funktionen sowie Inhalte
          </a>
        </li>
      </ul>
    </div>
  );

  const _contact_and_inquiry_management = (
    <div className="card-body">
      Bei der Kontaktaufnahme mit uns &#040;z.&nbsp;B. per Post,
      Kontaktformular, E-Mail, Telefon oder via soziale Medien&#041;
      <br /> sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden
      die Angaben der anfragenden Personen verarbeitet soweit dies zur
      Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen
      erforderlich ist.
      <br />
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> <br />
          Kontaktdaten &#040;z.&nbsp;B. E-Mail, Telefonnummern&#041;;
          Inhaltsdaten &#040;z.&nbsp;B. Eingaben in Onlineformularen&#041;;
          Nutzungsdaten &#040;z.&nbsp;B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten&#041;; Meta-, Kommunikations- und
          Verfahrensdaten &#040;z.&nbsp;.B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus&#041;.
        </li>
        <li>
          <b>Betroffene Personen:</b>
          <br /> Kommunikationspartner.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b>
          <br /> Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung
          von Anfragen; Feedback &#040;z.&nbsp;B. Sammeln von Feedback via
          Online-Formular&#041;. Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b>
          <br /> Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
          DSGVO&#041;.
        </li>
      </ul>
    </div>
  );

  const _relevant_legal_bases = (
    <div className="card-body">
      <b>Maßgebliche Rechtsgrundlagen nach der DSGVO: </b>
      <br />
      Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
      auf deren Basis wir personenbezogene Daten verarbeiten. <br />
      Bitte nehmen Sie zur Kenntnis, <br />
      dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem
      bzw. unserem Wohn- oder Sitzland gelten können.
      <br />
      Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein,
      teilen wir Ihnen diese in der Datenschutzerklärung mit.
      <br />
      <br />
      <ul>
        <li>
          <b>Einwilligung &#040;Art. 6 Abs. 1 S. 1 lit. a&#041; DSGVO&#041;</b>
          <br />
          Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
          sie betreffenden personenbezogenen Daten für einen spezifischen Zweck
          oder mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <b>
            Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
            DSGVO&#041;
          </b>
          <br />
          Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <b>Nationale Datenschutzregelungen in Deutschland: </b>
      <br />
      Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
      Regelungen zum Datenschutz in Deutschland. <br />
      Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch
      personenbezogener Daten bei der Datenverarbeitung
      &#040;Bundesdatenschutzgesetz – BDSG&#041;. <br />
      Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft,
      zum Recht auf Löschung, zum Widerspruchsrecht, <br />
      zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
      Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
      Entscheidungsfindung im Einzelfall einschließlich Profiling. <br />
      Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur
      Anwendung gelangen.
      <br />
      <br />
      <b>Hinweis auf Geltung DSGVO und Schweizer DSG: </b>
      <br />
      Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
      schweizerischen Bundesgesetz über den Datenschutz &#040;Schweizer
      DSG&#041; als auch nach der Datenschutzgrundverordnung &#040;DSGVO&#041;.{" "}
      <br />
      Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren
      räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet
      werden. <br />
      <br />
      Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung"
      von „Personendaten", "überwiegendes Interesse" und "besonders
      schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe
      „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes
      Interesse" und "besondere Kategorien von Daten" verwendet. Die gesetzliche
      Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG
      weiterhin nach dem Schweizer DSG bestimmt.
    </div>
  );

  const _overview_dataprocessing = (
    <div className="card-body">
      Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
      Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
      Personen.
      <br />
      <br />
      <h3>
        <b>Arten der verarbeiteten Daten</b>
      </h3>
      <ul>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
      </ul>
      <br />
      <h3>
        <b>Kategorien betroffener Personen</b>
      </h3>
      <ul>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
      </ul>
      <br />
      <h3>
        <b>Zwecke der Verarbeitung</b>
      </h3>
      <ul>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
        <li>Feedback.</li>
        <li>Marketing.</li>
        <li>
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>Informationstechnische Infrastruktur.</li>
      </ul>
    </div>
  );

  const _preamble = (
    <div className="card-body">
      <h2>
        <b>
          <u>Präambel</u>
        </b>
      </h2>
      Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,{" "}
      <br />
      welche Arten Ihrer personenbezogenen Daten &#040;nachfolgend auch kurz als
      "Daten" bezeichnet&#041; <br />
      wir zu welchen Zwecken und in welchem Umfang verarbeiten. <br />
      <br />
      Die Datenschutzerklärung gilt für alle von uns durchgeführten
      Verarbeitungen personenbezogener Daten, <br />
      sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere
      auf unseren Webseiten, <br />
      in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie
      z.&nbsp;B. unserer Social-Media-Profile <br />
      &#040;nachfolgend zusammenfassend bezeichnet als "Onlineangebot"&#041;.
      <br />
      Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
      <br />
      <br />
      Stand: 21. Juni 2024
    </div>
  );

  const _person_in_charge = (
    <div className="card-body">
      Burgverein Hohenfels e.V.
      <br />
      Mail-Adresse: <br />
      <a
        href="mailto:burg.hohenfels@googlemail.com"
        style={{ color: "#212529" }}
      >
        burg.hohenfels@googlemail.com
      </a>
    </div>
  );

  const _provision_onlineoffer_and_webhosting = (
    <div className="card-body">
      Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
      Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
      IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
      unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
      übermitteln.
      <br />
      <br />
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b> <br />
          Nutzungsdaten &#040;z.&nbsp;B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten&#041;; Meta-, Kommunikations- und
          Verfahrensdaten &#040;z.&nbsp;.B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus&#041;.
        </li>
        <li>
          <b>Betroffene Personen:</b> <br />
          Nutzer &#040;z.&nbsp;.B. Webseitenbesucher, Nutzer von
          Onlinediensten&#041;.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b>
          <br /> Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit; Informationstechnische Infrastruktur
          &#040;Betrieb und Bereitstellung von Informationssystemen und
          technischen Geräten &#040;Computer, Server etc.&#041;.&#041;.
          Sicherheitsmaßnahmen.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b>
          <br /> Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
          DSGVO&#041;.
        </li>
      </ul>
      <br />
      <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
      <ul>
        <li>
          <b>Erhebung von Zugriffsdaten und Logfiles: </b>
          <br />
          Der Zugriff auf unser Onlineangebot wird in Form von so genannten
          "Server-Logfiles" protokolliert.
          <br />
          Zu den Serverlogfiles können die Adresse und Name der abgerufenen
          Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene
          Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst
          Version, das Betriebssystem des Nutzers, Referrer URL &#040;die zuvor
          besuchte Seite&#041; und im Regelfall IP-Adressen und der anfragende
          Provider gehören. <br />
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.&nbsp;B., um eine Überlastung der Server zu
          vermeiden &#040;insbesondere im Fall von missbräuchlichen Angriffen,
          sogenannten DDoS-Attacken&#041; und zum anderen, um die Auslastung der
          Server und ihre Stabilität sicherzustellen;
          <br />
          <span>
            <b>Rechtsgrundlagen:</b> Berechtigte Interessen &#040;Art. 6 Abs. 1
            S. 1 lit. f&#041; DSGVO&#041;.
          </span>
          <br />
          <br />
          <b>Löschung von Daten:</b>
          <br />
          Logfile-Informationen werden für die Dauer von maximal 30 Tagen
          gespeichert und danach gelöscht oder anonymisiert. Daten, deren
          weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
          endgültigen Klärung des jeweiligen Vorfalls von der Löschung
          ausgenommen.
        </li>
      </ul>
    </div>
  );

  const _plugins = (
    <div className="card-body">
      Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,{" "}
      <br />
      die von den Servern ihrer jeweiligen Anbieter &#040;nachfolgend bezeichnet
      als "Drittanbieter"&#041; bezogen werden. <br />
      Dabei kann es sich zum Beispiel um Grafiken, Videos oder Stadtpläne
      handeln &#040;nachfolgend einheitlich bezeichnet als "Inhalte"&#041;.
      <br />
      Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte
      die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die
      Inhalte nicht an deren Browser senden könnten. <br />
      Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder
      Funktionen erforderlich. <br />
      Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter
      die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
      <br />
      Drittanbieter können ferner sogenannte Pixel-Tags &#040;unsichtbare
      Grafiken, auch als "Web Beacons" bezeichnet&#041; für statistische oder
      Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen,
      wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet
      werden. <br />
      Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der
      Nutzer gespeichert werden und unter anderem technische Informationen zum
      Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit
      sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als
      auch mit solchen Informationen aus anderen Quellen verbunden werden.
      <br />
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b>
          <br />
          Nutzungsdaten &#040;z.&nbsp;B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten&#041;; Meta-, Kommunikations- und
          Verfahrensdaten &#040;z.&nbsp;.B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus&#041;.
        </li>
        <li>
          <b>Betroffene Personen:</b>
          <br /> Nutzer &#040;z.&nbsp;.B. Webseitenbesucher, Nutzer von
          Onlinediensten&#041;.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b>
          <br /> Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen &#040;Art. 6 Abs. 1 S.
          1 lit. f&#041; DSGVO&#041;.
        </li>
      </ul>
      <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
      <ul>
        <li>
          <b>
            Einbindung von Drittsoftware, Skripten oder Frameworks
            &#040;z.&nbsp;B. jQuery&#041;:
          </b>
          <br />
          Wir binden in unser Onlineangebot Software ein, <br />
          die wir von Servern anderer Anbieter abrufen &#040;z.&nbsp;B.
          Funktions-Bibliotheken, <br />
          die wir zwecks Darstellung oder Nutzerfreundlichkeit&nbsp;unseres
          Onlineangebotes verwenden&#041;. <br />
          Hierbei erheben die jeweiligen Anbieter die IP-Adresse der Nutzer und
          können diese zu Zwecken der&nbsp;Übermittlung der Software an den
          Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
          Auswertung und Optimierung ihres Angebotes verarbeiten. <br />
          <span>
            <b>Rechtsgrundlagen:</b>
            <br /> Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
            DSGVO&#041;.
          </span>
        </li>
        <br />
        <li>
          <b>Google Fonts &#040;Bereitstellung auf eigenem Server&#041;:</b>
          <br />
          Bereitstellung von Schriftarten-Dateien zwecks einer
          nutzerfreundlichen Darstellung unseres Onlineangebotes;
          <br />
          <b>Dienstanbieter:</b> <br />
          Die Google Fonts werden auf unserem Server gehostet, es werden keine
          Daten an Google übermittelt;
          <br />
          <span>
            <b>Rechtsgrundlagen:</b>
            <br /> Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
            DSGVO&#041;.
          </span>
        </li>
        <br />
        <li>
          <b>Font Awesome &#040;Bereitstellung auf eigenem Server&#041;:</b>
          <br />
          Darstellung von Schriftarten und Symbolen;
          <br />
          <b>Dienstanbieter:</b> <br />
          Die Font Awesome Icons werden auf unserem Server gehostet, es werden
          keine Daten an den Anbieter von Font Awesome übermittelt;
          <br />
          <span>
            <b>Rechtsgrundlagen:</b>
            <br />
            Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
            DSGVO&#041;.
          </span>
        </li>
      </ul>
      <a
        href="https://datenschutz-generator.de/"
        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
        Schwenke
      </a>
    </div>
  );

  const _security_measures = (
    <div className="card-body">
      Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
      des Stands der Technik, <br />
      der Implementierungskosten und der Art, des Umfangs, <br />
      der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
      Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte
      und Freiheiten natürlicher Personen geeignete technische und
      organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
      gewährleisten.
      <br />
      Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
      Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
      elektronischen Zugangs zu den Daten als auch des sie betreffenden
      Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
      ihrer Trennung. <br />
      Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
      Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
      Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
      personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
      Hardware, Software sowie Verfahren entsprechend dem Prinzip des
      Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.
    </div>
  );

  const _transmission_of_personal_data = (
    <div className="card-body">
      Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
      dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
      Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
      offengelegt werden. <br />
      Zu den Empfängern dieser Daten können z.&nbsp;B. mit IT-Aufgaben
      beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in
      eine Webseite eingebunden werden, gehören. In solchen Fällen beachten wir
      die gesetzlichen Vorgaben und schließen insbesondere entsprechende
      Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
      Empfängern Ihrer Daten ab.
    </div>
  );

  const _rights_persons_concerned = (
    <div className="card-body">
      Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene
      nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15
      bis 21 DSGVO ergeben:
      <ul>
        <li>
          <b>Widerspruchsrecht:</b> <br />
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung der Sie
          betreffenden personenbezogenen Daten, die aufgrund von Art. <br />
          6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
          gilt auch für ein auf diese Bestimmungen gestütztes Profiling. <br />
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
          Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
          gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
          zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
          Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        </li>{" "}
        <br />
        <li>
          <b>Widerrufsrecht bei Einwilligungen:</b> <br />
          Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>{" "}
        <br />
        <li>
          <b>Auskunftsrecht:</b> <br />
          Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
          betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
          sowie auf weitere Informationen und Kopie der Daten entsprechend den
          gesetzlichen Vorgaben.
        </li>{" "}
        <br />
        <li>
          <b>Recht auf Berichtigung:</b> <br />
          Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
          Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
          Sie betreffenden unrichtigen Daten zu verlangen.
        </li>
        <br />
        <li>
          <b>Recht auf Löschung und Einschränkung der Verarbeitung:</b>
          <br />
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
          bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
          Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <br />
        <li>
          <b>Recht auf Datenübertragbarkeit:</b> <br />
          Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt
          haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
          gängigen und maschinenlesbaren Format zu erhalten oder deren
          Übermittlung an einen anderen Verantwortlichen zu fordern.
        </li>
        <br />
        <li>
          <b>Beschwerde bei Aufsichtsbehörde:</b> <br /> Sie haben unbeschadet
          eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
          Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
          ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn
          Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
        </li>
      </ul>
    </div>
  );

  const _use_of_cookies = (
    <div className="card-body">
      Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke,
      <br />
      die Informationen auf Endgeräten speichern und Informationen aus den
      Endgeräten auslesen. Z.&nbsp;B. um den Login-Status in einem Nutzerkonto,
      einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
      verwendete Funktionen eines Onlineangebotes speichern.
      <br />
      Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden,
      z.&nbsp;B. zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von
      Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.
      <br />
      <br />
      <b>Hinweise zur Einwilligung: </b>
      <br />
      Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein.{" "}
      <br />
      Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer
      wenn diese gesetzlich nicht gefordert ist. <br />
      Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und
      das Auslesen der Informationen, also auch von Cookies, unbedingt
      erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
      gewünschten Telemediendienst &#040;also unser Onlineangebot&#041; zur
      Verfügung zu stellen. <br />
      Zu den unbedingt erforderlichen Cookies gehören in der Regel Cookies mit
      Funktionen, die der Anzeige und Lauffähigkeit des Onlineangebotes , dem
      Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und
      Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der Bereitstellung der
      Haupt- und Nebenfunktionen des von den Nutzern angeforderten
      Onlineangebotes zusammenhängenden Zwecken dienen. <br />
      Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
      kommuniziert und enthält die Informationen zu der jeweiligen
      Cookie-Nutzung.
      <br />
      <br />
      <b>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </b>
      <br />
      Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
      personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten,
      hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. <br />
      Falls die Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung
      Ihrer Daten die erklärte Einwilligung. <br />
      Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
      Grundlage unserer berechtigten Interessen &#040;z.&nbsp;B. an einem
      betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
      seiner Nutzbarkeit&#041; verarbeitet oder, wenn dies im Rahmen der
      Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von
      Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
      erfüllen. <br />
      Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären
      wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
      Einwilligungs- und Verarbeitungsprozessen auf.
      <br />
      <br />
      <b>Speicherdauer:&nbsp;</b>
      <br />
      Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies
      unterschieden:
      <br />
      <ul>
        <li>
          <b>
            Temporäre Cookies &#040;auch: Session- oder Sitzungs-Cookies&#041;:
          </b>
          <br />
          &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
          ein Online-Angebot verlassen und sein Endgerät &#040;z.&nbsp;B.
          Browser oder mobile Applikation&#041; geschlossen hat.
        </li>
        <li>
          <b>Permanente Cookies:</b>
          <br />
          Permanente Cookies bleiben auch nach dem Schließen des Endgerätes
          gespeichert. So können beispielsweise der Login-Status gespeichert
          oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine
          Website erneut besucht. Ebenso können die mit Hilfe von Cookies
          erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden.
          Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
          Speicherdauer von Cookies mitteilen &#040;z.&nbsp;B. im Rahmen der
          Einholung der Einwilligung&#041;, sollten Nutzer davon ausgehen, dass
          Cookies permanent sind und die Speicherdauer bis zu zwei Jahre
          betragen kann.
        </li>
        <br />
      </ul>
      <b>
        Allgemeine Hinweise zum Widerruf <br />
        und Widerspruch &#040;sog. "Opt-Out"&#041;:
        <br />
      </b>
      Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
      widerrufen und der Verarbeitung entsprechend den gesetzlichen Vorgaben
      widersprechen. Hierzu können Nutzer unter anderem die Verwendung von
      Cookies in den Einstellungen ihres Browsers einschränken &#040;wobei
      dadurch auch die Funktionalität unseres Onlineangebotes eingeschränkt sein
      kann&#041;. <br />
      Ein Widerspruch gegen die Verwendung von Cookies zu
      Online-Marketing-Zwecken kann auch über die Websites <br />
      <a href="https://optout.aboutads.info/" target="_new">
        https://optout.aboutads.info
      </a>
      <br />
      und <br />
      <a href="https://www.youronlinechoices.com/" target="_new">
        https://www.youronlinechoices.com/
      </a>
      <br />
      erklärt werden.
      <br />
      <br />
      <ul>
        <li>
          <b>Rechtsgrundlagen:</b> Berechtigte Interessen &#040;Art. 6 Abs. 1 S.
          1 lit. f&#041; DSGVO&#041;. Einwilligung &#040;Art. 6 Abs. 1 S. 1 lit.
          a&#041; DSGVO&#041;.
        </li>
      </ul>
      <br />
      <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
      <br />
      <ul>
        <li>
          <b>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:</b>
          <br />
          Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die
          Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
          Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
          Anbietern eingeholt wird. <br />
          Dieses Verfahren dient der Einholung, Protokollierung, Verwaltung und
          dem Widerruf von Einwilligungen, insbesondere bezogen auf den Einsatz
          von Cookies und vergleichbaren Technologien, die zur Speicherung, zum
          Auslesen und zur Verarbeitung von Informationen auf den Endgeräten der
          Nutzer eingesetzt werden. <br />
          Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für
          die Nutzung von Cookies und die damit verbundenen Verarbeitungen von
          Informationen, einschließlich der im
          Einwilligungs-Management-Verfahren genannten spezifischen
          Verarbeitungen und Anbieter, eingeholt. <br />
          Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu
          verwalten und zu widerrufen. <br />
          Die Einwilligungserklärungen werden gespeichert, um eine erneute
          Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß der
          gesetzlichen Anforderungen führen zu können.
          <br />
          Die Speicherung erfolgt serverseitig und/oder in einem Cookie
          &#040;sogenanntes Opt-In-Cookie&#041; oder mittels vergleichbarer
          Technologien, um die Einwilligung einem spezifischen Nutzer oder
          dessen Gerät zuordnen zu können. <br />
          Sofern keine spezifischen Angaben zu den Anbietern von
          Einwilligungs-Management-Diensten vorliegen, gelten folgende
          allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung
          beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer
          Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der
          Einwilligung, den Angaben zum Umfang der Einwilligung &#040;z.&nbsp;B.
          betreffende Kategorien von Cookies und/oder Diensteanbieter&#041;
          sowie Informationen über den Browser, das System und das verwendete
          Endgerät gespeichert wird;
          <br />
          <br />
          <span>
            <b>Rechtsgrundlagen:</b> Einwilligung &#040;Art. 6 Abs. 1 S. 1 lit.
            a&#041; DSGVO&#041;.
          </span>
        </li>
      </ul>
    </div>
  );

  const _social_media = (
    <div className="card-body">
      Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
      verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
      Nutzern zu kommunizieren oder um Informationen über uns anzubieten. <br />
      Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
      der Europäischen Union verarbeitet werden können. <br />
      Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.&nbsp;B.
      die Durchsetzung der Rechte der Nutzer erschwert werden könnte.
      <br />
      Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
      Regelfall für Marktforschungs- und Werbezwecke verarbeitet. <br />
      So können z.&nbsp;B. anhand des Nutzungsverhaltens und sich daraus
      ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
      <br />
      Die Nutzungsprofile können wiederum verwendet werden, um z.&nbsp;B.
      Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
      mutmaßlich den Interessen der Nutzer entsprechen. <br />
      Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
      gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer
      gespeichert werden. <br />
      Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den
      Nutzern verwendeten Geräte gespeichert werden &#040;insbesondere, wenn die
      Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
      eingeloggt sind&#041;.
      <br />
      Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und
      der Widerspruchsmöglichkeiten &#040;Opt-Out&#041; verweisen wir auf die
      Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
      <br />
      Auch im Fall von Auskunftsanfragen und der Geltendmachung von
      Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
      den Anbietern geltend gemacht werden können. <br />
      Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können
      direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. <br />
      Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
      <br />
      <ul>
        <li>
          <b>Verarbeitete Datenarten:</b>
          <br /> Kontaktdaten &#040;z.&nbsp;B. E-Mail, Telefonnummern&#041;;
          Inhaltsdaten &#040;z.&nbsp;B. Eingaben in Onlineformularen&#041;;
          Nutzungsdaten &#040;z.&nbsp;B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten&#041;; Meta-, Kommunikations- und
          Verfahrensdaten &#040;z.&nbsp;.B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus&#041;.
        </li>
        <li>
          <b>Betroffene Personen:</b>
          <br /> Nutzer &#040;z.&nbsp;.B. Webseitenbesucher, Nutzer von
          Onlinediensten&#041;.
        </li>
        <li>
          <b>Zwecke der Verarbeitung:</b>
          <br /> Kontaktanfragen und Kommunikation; Feedback &#040;z.&nbsp;B.
          Sammeln von Feedback via Online-Formular&#041;. Marketing.
        </li>
        <li>
          <b>Rechtsgrundlagen:</b>
          <br /> Berechtigte Interessen &#040;Art. 6 Abs. 1 S. 1 lit. f&#041;
          DSGVO&#041;.
        </li>
      </ul>
      <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
      <ul>
        <li>
          <b>Instagram: </b>
          <br />
          Soziales Netzwerk
          <br />
          <b>Dienstanbieter:</b>
          <br />
          Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04 X2K5,
          Irland;
          <br />
          <span>
            <b>Rechtsgrundlagen:</b> Berechtigte Interessen &#040;Art. 6 Abs. 1
            S. 1 lit. f&#041; DSGVO&#041;;
          </span>
          <br />
          <b>Website:</b>
          <br />
          <a href="https://www.instagram.com" target="_blank">
            https://www.instagram.com
          </a>
          <br />
          <b>Datenschutzerklärung:</b>
          <br />
          <a href="https://instagram.com/about/legal/privacy" target="_blank">
            https://instagram.com/about/legal/privacy
          </a>
          <br />
          <b>Grundlage Drittlandtransfers:</b>
          <br />
          Data Privacy Framework &#040;DPF&#041;.
        </li>
      </ul>
    </div>
  );

  useNavigationEffect();
  return (
    <div id="dataprotection" className="webpage">
      <Container id="dp-site" className="main-container">
        <Card id="preamble">
          <Card.Header id="dp-title">Datenschutzerklärung</Card.Header>
          {_preamble}
        </Card>
        <Card>
          <Card.Header>Inhaltsübersicht</Card.Header>
          {_contents_preview}
        </Card>
        <Card id="person_in_charge">
          <Card.Header>Verantwortlicher</Card.Header>
          {_person_in_charge}
        </Card>
        <Card id="overview_dataprocessing">
          <Card.Header>Übersicht der Verarbeitungen</Card.Header>
          {_overview_dataprocessing}
        </Card>
        <Card id="relevant_legal_bases">
          <Card.Header>Maßgebliche Rechtsgrundlagen</Card.Header>
          {_relevant_legal_bases}
        </Card>
        <Card id="security_measures">
          <Card.Header>Sicherheitsmaßnahmen</Card.Header>
          <Card.Body>{_security_measures}</Card.Body>
        </Card>
        <Card id="transmission_of_personal_data">
          <Card.Header>Übermittlung von personenbezogenen Daten</Card.Header>
          {_transmission_of_personal_data}
        </Card>
        <Card id="rights_persons_concerned">
          <Card.Header>Rechte der betroffenen Personen</Card.Header>
          {_rights_persons_concerned}
        </Card>
        <Card id="use_of_cookies">
          <Card.Header>Einsatz von Cookies</Card.Header>
          {_use_of_cookies}
        </Card>
        <Card id="provision_onlineoffer_and_webhosting">
          <Card.Header>
            Bereitstellung des Onlineangebotes und Webhosting
          </Card.Header>
          {_provision_onlineoffer_and_webhosting}
        </Card>
        <Card id="contact_and_inquiry_management">
          <Card.Header>Kontakt- und Anfragenverwaltung</Card.Header>
          {_contact_and_inquiry_management}
        </Card>

        <Card id="social_media">
          <Card.Header>
            Präsenzen in sozialen Netzwerken
            <br /> (Social Media)
          </Card.Header>
          {_social_media}
        </Card>

        <Card id="plugins">
          <Card.Header>
            Plugins und eingebettete Funktionen sowie Inhalte
          </Card.Header>
          <Card.Body>{_plugins}</Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default DataProtection;
