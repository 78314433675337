import React from "react";

import { Container } from "react-bootstrap";
import CardWithImageComponent from "../../components/CardWithImageComponent";
import useNavigationEffect from "../../effects/NavigationEffect.js";

function AssociationChairman() {
  useNavigationEffect();
  const text = (
    <div className="chairman-desc-text">
      <span id="chairmain-desc">
        Oben links nach rechts abgebildet:
        <br />
        Georg Kuhn-Gaber, Andreas Göbel, Heidemarie Korsina, Karin Dobrowohl{" "}
        <br />
        Unten links nach rechts abgebildet:
        <br />
        Jens Schmalz, Stefan Rehn, Timon Flemming
      </span>
      <br />
      <br />
      Seit dem Jahr 2022 wird der Vorsitz des Vereines aus einem
      geschäftsführenden Vorstand, bestehend aus:
      <br />
      Stefan Rehn, Georg Kuhn-Gaber, Jens Schmalz und Andreas Göbel. <br />
      Als Beisitzer fungieren Karin Dobrowohl, Heidemarie Kosina und Timon
      Flemming.
      <br />
      Als kommender zusätzlicher Beisitzer unterstützt seit Januar 2024 schon
      Julian Lorenz den Vorstand.
      <br />
      Nach einer intensiven Gedankensammlung (Mind-Map) wurden 2023 Ziele und
      Srategien erarbeitet. <br />
      Hervorzuheben ist dabei die stärkere Hervorhebung der überregionalen
      Bedeutung der Hohenfels Anlagen und somit auch des Vereines, vor allem für
      die heutige Großgemeinde Dautphetal, und deren angrenzenden Gemeinden.
      Zudem sollen die Hohenfels-Anlagen nach und nach für touristische
      Aktivitäten noch attraktiver gemacht werden.
      <br />
      Vorrangiges Ziel ist derzeit das{" "}
      <a href="/projekte/keller">„Projekt Kellergewölbe“</a> bei dem eine
      kostspielige Restauration ansteht.
      <br />
      <br />
      Beweggründe für die Vereinsarbeit des Vorstandes:
      <ul>
        <li>
          Stefan Rehn
          <blockquote>
            <cite>
              Die Hohenfels-Ruinen sind Bestandteil meiner frühesten
              Kindheitserinnerungen, geprägt durch Spaziergänge zum
              Kellergewölbe mit meinem Großvater, der mir dabei Geschichten der
              Raubritter erzählte.
              <br />
              Seit ich mich im Vorstand des Vereines engagiere, habe ich
              erkannt, wie sehr die geschichtliche Bedeutung des Hohenfels für
              das obere Lahntal unterschätzt wird, oder nicht bekannt ist.
            </cite>
          </blockquote>
        </li>
        <li>
          Jens Schmalz
          <blockquote>
            <cite>
              Für mich ist der Erhalt der Ruine und die Sensibilisierung der
              Gemeinde Dautphetal, zu diesem Denkmal wichtig. <br />
              Auch die geschichtlichen Hintergründe der Ruine aufzudecken und
              nachvollziehbar zu machen ist mir ein Anliegen.
            </cite>
          </blockquote>
        </li>
        <li>
          Andreas Göbel
          <blockquote>
            <cite>
              Ich interessiere mich schon lange für das Thema Mittelalter.{" "}
              <br />
              Hier im Burgverein Hohenfels kann ich mich einbringen, um selbst
              und mit Gleichgesinnten umfassend rund um das Mittelalter neues zu
              erleben und erfahren.
            </cite>
          </blockquote>
        </li>
        <li>
          Timon Flemming
          <blockquote>
            <cite>
              Wir sind mehr als nur EIN Burgvereinverein! <br />
              Die Geschichte und Hintergründe der Ruine zu kennen ist wichtig!
              <br />
              Ich möchte den Verein gerne präsenter und zugänglicher machen und
              kümmere mich deshalb um den digitalen Auftritt.
            </cite>
          </blockquote>
        </li>
        <li>
          Julian Lorenz
          <blockquote>
            <cite>
              Ich engagiere mich im Burgverein, um die Entstehungsgeschichte der
              Region besser zu verstehen und ihr Andenken daran zu bewahren.
            </cite>
          </blockquote>
        </li>
      </ul>
    </div>
  );
  return (
    <div id="association-chairman" className="webpage">
      <Container className="main-container">
        <CardWithImageComponent
          id="chairman-site-card"
          header="Der Vorstand"
          image="../../images/general_images/vorstand.jpg"
          content={text}
        />
      </Container>
    </div>
  );
}

export default AssociationChairman;
