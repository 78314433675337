import React from "react";

import { Container } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent";
function AssociationLogo() {
  useNavigationEffect();
  return (
    <div id="association-logo" className="webpage">
      <Container className="main-container">
        <CardWithImageComponent
          id="logo-site-card"
          className="card-with-image"
          header="Vereinslogo"
          image="../../images/general_images/logo-transparent.png"
          text={
            <div>
              Ein stehender Ritter welcher links ein historisches, <br />
              original-Hohenfels-Wappen und rechts das idealisierte Ortswappen
              von Allendorf am Hohenfels in Händen hält.
              <br />
              Das historische Wappen ist das älteste in der heldmannschen
              Puplikation abgebildete Wappen. <br />
              Es stammt aus dem Jahr 1249 und wurde von Volpert Hosekin geführt.{" "}
              <br />
              Volpert Hosekin von Hohenfels war einer jener 5 Hohenfelsern, die
              sich dem Herrschaftsanspruch Sophie von Brabants (der Stammmutter
              des Hauses Hessen) im Jahr 1249 unterwerfen mussten,
              <br />
              indem sie dieser ihr Schloss Hohenfels übertragen mussten und es
              wieder zu Lehen nehmen durften.
              <br />
              Noch ist kein Stern im Wappen (links) zu sehen, welcher den
              Protest gegen die aufkommende Landgrafschaft Hessen symbolisiert.
              <br />
              Dieser „Protest-Stern“ ist jedoch in späteren Wappen des Hauses
              Hohenfels enthalten und wurde auch in das idealisierte Wappen
              (rechts), dem Ortswappen Allendorfs übernommen. <br />
              Der Stern kann als Mahnmal für die spannungsgeladene damalige Zeit
              gesehen werde, <br />
              als sich neue Machtverhältnisse bildeten und die alteingesessenen
              Adligen das Nachsehen hatten.
            </div>
          }
          footer={
            <div>
              <span style={{ textAlign: "center" }}>
                - Burgverein Hohenfels e.V. –
              </span>
              <span style={{ textAlign: "center" }}>Im Juni 2023</span>
            </div>
          }
        />
      </Container>
    </div>
  );
}

export default AssociationLogo;
