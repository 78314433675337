import React from "react";

import { Container, Card, CardBody } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

function GuildHistory() {
  useNavigationEffect();
  return (
    <div id="guild-history" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Geschichte des Vereins</Card.Header>
          <Card.Body>
            <Card.Text>
              Das Interesse der Burganlagen und seiner einflussreichen Bewohner
              existiert spätestens seit den 1850er Jahren. Aus dieser Zeit
              existiert bereits eine kleine Abhandlung mit Karte (auf welcher
              das Kellergewölbe eingezeichnet ist) des Hohenfelses. 45 Jahre
              später, im Jahr 1895 veröffentlichte der Pfarrer und Historiker
              August Heldmann (1834-1919) seine Abhandlung „Zur Geschichtes des
              Gerichtes Viermünden und seiner Geschlechter – II. Das Geschlecht
              von Hohenfels“ in der Zeitschrift für hessische Geschichte und
              Landeskunde. Diese zusammenfassende und dennoch sehr detailreiche
              Übersicht bildet noch heute die Basis zur Geschichte des
              Hohenfels. Die örtliche Bevölkerung war stets interessiert an den
              neu zutage tretenden Erkenntnissen über ihren namensgebenden
              Hausberg, so verwundert es auch nicht, dass Veranstaltungen am
              Fuße der Hohenfels-Anlagen genauso überliefert sind, wie eine
              Fahne auf den Ruinen, welche der bewaldeten Bergkuppe eine Spur
              der ehemaligen Bedeutung verlieh. Seit der Wende des 19./20. Jh.
              sind auch einzelne, leider nicht immer fachgerechte Grabungen an
              den Ruinen belegt. Das Interesse der Bevölkerung, besonders
              derjenigen in deren Gemarkung sich das Arreal befindet, nämlich
              die des Dautphetaler Ortsteiles Allendorf, spiegelt sich
              beispielsweise auch in der Namensgebung einer Gaststätte aus dem
              20. Jahrhundert wieder. Vor diesem Hintergrund ist es nicht
              verwunderlich, dass die Gründung eines Vereins, welcher die
              geschichtsträchtigen Ruinen kernthematisch behandelt, schon vor
              offizieller Vereinsgründung in vielen, auch politischen Köpfen
              ihren Anfang nahm. Dank einer Förderung im Rahmen des
              „Lahn-Dill-Bergland“-Programmes, ergab sich in den 1990er Jahren
              dann endlich die Möglichkeit der Geburtshilfe für unseren Verein.
            </Card.Text>
          </Card.Body>
        </Card>
        <CardWithImageComponent
          id="gruendung-01"
          header="Die Vereinsgründung"
          className="card-with-image"
          image="../images/general_images/Foto_und_Artikel_Vereinsgründung.jpg"
          text={
            <div>
              Mit Datum des 20. Februar 1997 unterzeichneten 36
              Gründungsmitglieder die Entstehung des „Burgverein Hohenfels
              e.V.“, welcher seitdem sowohl einen heimat- wie auch einen
              kulturgeschichtlichen Beitrag zur Gemeinde Dautphetal sowie
              darüber hinausgehend: auch für weite Teile des oberen Lahntales
              leistet.
              <br />
              Unserem ehemaligen ersten Vorsitzenden Erwin Klingelhöfer ist es
              zu Verdanken, dass unser Verein ein Informations- und
              Dokumentationszentrum im Dorfgemeinschaftshaus Allendorf betreuen
              kann: Das „Hohenfels Museum“
              <br />
            </div>
          }
        />
        <CardWithImageComponent
          id="ehrenamtspreis"
          className="card-with-image"
          image="../../images/general_images/Zeitungsartikel Ehrenamtspreis.jpg"
          text={
            <div>
              Im Jahr 1999 übernahm Reinhold Schmidt den Vorsitz, in seine
              Amtszeit fällt ein Vermessungsarbeiten des Hohenfels-Geländes,
              sowie eine Freilegung des Kellergewölbes der Westburg unter
              wissenschaftlicher Leitung. Die seit Gründung angestrengten
              Erfolge wurden im Jahr 2003 mit dem Ehrenamtspreis für
              Denkmalpflege belohnt.
              <br />
            </div>
          }
        />
        <Card>
          <Card.Body>
            <Card.Text>
              Die Aufmerksamkeit des Vereines richtet sich auch auf touristische
              Aktivitäten und ihre Einbindung in regionale Angebote und
              Strategien, um die Vermittlung der großen regionalen
              geschichtlichen Bedeutung an die Öffentlichkeit, breit
              aufgestellt, realisieren zu können.
              <br />
              Nach über 20 jährigem Vorsitz verstarb unser bis dahin erster
              Vorsitzender viel zu früh und hinterließ aufgrund seines
              einmaligen geschichtlichen Wissens und Engagements eine große
              Lücke. Seit 2022 werden wird der Verein unter einem
              geschäftsführenden Gesamtvorstand geleitet. <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <a href="/verein/vorstand">Mehr zum aktuellen Vorstand</a>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <CardWithImageComponent
          id="zeitung-burgverein-erwacht-zum-leben"
          className="card-with-image"
          image="../images/general_images/zeitung-burgverein-erwacht.png"
        />
      </Container>
    </div>
  );
}

export default GuildHistory;
