// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import axios from "axios";

import NavigationBar from "./components/Navbar";

import Home from "./pages/Home";
import DataProtection from "./pages/DataProtection";
import Imprint from "./pages/Imprint";
import BecomeMember from "./pages/BecomeMember";

//ruin Ruine
import History from "./pages/ruin/History";
import Position from "./pages/ruin/Position";
import RuinImages from "./pages/ruin/Images";
import Visualization from "./pages/ruin/Visualization";

//guild Der Verein "Wer wir sind"
import Tasks from "./pages/guild/Tasks";
import GuildHistory from "./pages/guild/History";
import AssociationChairman from "./pages/guild/AssociationChairman";
import Statute from "./pages/guild/Statute";
import AssociationLogo from "./pages/guild/AssociationLogo";
//import Donation from './pages/guild/Donation';

//projects
import CellarProject from "./pages/projects/Cellar";
import ModelProject from "./pages/projects/Models";
import DonationBoard from "./pages/projects/DonationBoard";
import LectureTrail from "./pages/projects/LectureTrail";
//import MidSummerNight from './pages/special/SummerNight';
import GuestBook from "./pages/GuestBook";

//LectureTrail
import EntranceBuchenau from "./pages/lecturetrail/EntranceBuchenau";
import EntranceCemetery from "./pages/lecturetrail/EntranceCemetery";
import EntranceSportField from "./pages/lecturetrail/EntranceSportField";

const TRACKING_ID = "G-3N1MT9W9EB"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

const isDevState = true;
function App() {
  const [totalVisitors, setTotalVisitors] = useState(0);

  const updateVisitorCount = async function () {
    try {
      await axios.post("https://burgverein-hohenfels.de/visitors/update");
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(function () {
    if (getCookieConsentValue() === "true") {
      updateVisitorCount();
    }
    fetchTotalVisitors();
  }, []);

  const fetchTotalVisitors = async function () {
    const response = await axios.post(
      "https://burgverein-hohenfels.de/visitors/total"
    );
    setTotalVisitors(response.data.totalVisitors);
  };

  function handleAcceptCookies() {
    updateVisitorCount();
    window.location.reload(true);
  }

  return (
    <div>
      <Router>
        <header>
          <NavigationBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/ruinen/geschichte" element={<History />} />
            <Route path="/ruinen/lage" element={<Position />} />
            <Route path="/ruinen/bilder" element={<RuinImages />} />
            <Route
              path="/ruinen/rekonstruktions-modelle"
              element={<Visualization />}
            />

            <Route path="/verein/aufgaben" element={<Tasks />} />
            <Route path="/verein/historie" element={<GuildHistory />} />
            <Route path="/verein/vorstand" element={<AssociationChairman />} />
            <Route path="/verein/satzung" element={<Statute />} />
            <Route path="/verein/logo" element={<AssociationLogo />} />

            <Route path="/projekte/keller" element={<CellarProject />} />
            <Route path="/projekte/modell" element={<ModelProject />} />
            <Route path="/projekte/lehrpfad" element={<LectureTrail />} />
            <Route path="/projekte/spendentafel" element={<DonationBoard />} />

            <Route path="/mitglied-werden" element={<BecomeMember />} />
            <Route path="/gaestebuch" element={<GuestBook />} />
            <Route path="/datenschutz" element={<DataProtection />} />
            <Route path="/impressum" element={<Imprint />} />

            {/**
             * LEHRPFAD
             */}
            <Route path="lehrpfad/eingang/buchennau" element={<EntranceBuchenau />} />
            <Route path="lehrpfad/eingang/friedhof" element={<EntranceCemetery />} />
            <Route path="lehrpfad/eingang/sportplatz" element={<EntranceSportField />} />

            <Route path="*" element={<Home />} />
          </Routes>
          <CookieConsent
            location="bottom"
            buttonText="Ich stimme zu"
            cookieName="cookies"
            buttonClasses="btn btn-dark"
            expires={150}
            onAccept={handleAcceptCookies}
          >
            Diese Website verwendet Cookies, um sicherzustellen, dass du die
            beste Erfahrung auf unserer Website erhältst.
          </CookieConsent>
        </main>
      </Router>
      <footer>
        <div id="visitors"> Gesamtbesucherzahl: {totalVisitors} </div>
        <a
          href="https://www.instagram.com/burgverein_hohenfels_lahn/"
          target="_blank"
        >
          <i
            className="fab fa-instagram"
            style={{ fontSize: "1.5rem", paddingRight: "0.5%" }}
          ></i>
          Folgt uns auf Instagram
        </a>
        <a href="mailto:burg.hohenfels@googlemail.com" id="footer-mail">
          <i
            className="fa fa-envelope"
            aria-hidden="true"
            style={{ fontSize: "1.4rem", paddingRight: "0.5%" }}
          ></i>
          Schreibt uns eine E-Mail
        </a>
        <div id="copyright">Copyright 2024, Burgverein Hohenfels e.V.</div>
      </footer>
    </div>
  );
}

export default App;
