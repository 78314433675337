import React, { useEffect, useState } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import useNavigationEffect from "../effects/NavigationEffect";
import axios from "axios";

function GuestBook() {
  const [entries, setEntries] = useState([]);
  const [page, setPage] = useState(0); // Tracks the current page of entries
  const [hasMore, setHasMore] = useState(true); // Tracks if there are more entries to show

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [author, setAuthor] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useNavigationEffect();

  useEffect(
    function () {
      fetchEntries();
    },
    [page]
  );

  async function fetchEntries() {
    try {
      const response = await axios.post(
        `https://burgverein-hohenfels.de/guestbook/getEntries?page=${page}`
      );
      const newEntries = response.data;

      // Check if there are more than 5 entries
      if (newEntries.length < 5) {
        setHasMore(false);
      }

      setEntries((prevEntries) => [...prevEntries, ...newEntries]);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!title || !message || !author) {
      setError("Alle Felder müssen ausgefüllt sein.");
      return;
    }

    try {
      const response = await axios.post("https://burgverein-hohenfels.de/guestbook/postEntry", {
        title,
        message,
        author,
      });

      if (response.status === 200) {
        setSuccess("Eintrag erfolgreich hinzugefügt!");
        setEntries([
          { title, message, author, createdAt: new Date() },
          ...entries,
        ]); // Add new entry locally
        setTitle(""); // Clear form fields
        setMessage("");
        setAuthor("");
      }
    } catch (error) {
      console.error("Error posting entry:", error);
      setError("Es gab einen Fehler beim Hinzufügen des Eintrags.");
    }
  }

  function loadMore() {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <div id="guestbook" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Gästebuch</Card.Header>
          <Card.Body>
            <Card.Text>
              Disclaimer: <br />
              Unser Gästebuch wird eigenhändig moderiert, um eine freundliche
              und respektvolle Atmosphäre zu gewährleisten. <br />
              Wir behalten uns das Recht vor, Einträge zu prüfen und
              gegebenenfalls ohne vorherige Ankündigung zu löschen, <br />
              insbesondere wenn sie beleidigend, unangemessen oder in anderer
              Weise gegen unsere Richtlinien verstoßen. <br />
              Vielen Dank für euer Verständnis und eure Beiträge!
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Richtlinien</Card.Header>
          <Card.Body>
            Um sicherzustellen, dass unser Gästebuch ein angenehmer Ort für alle
            bleibt, <br />
            bitten wir euch, die folgenden Richtlinien zu beachten:
            <br />
            <ol>
              <li>
                Respektvoller Umgang: <br />
                Behandelt andere Nutzer und ihre Meinungen mit Respekt. <br />
                Beleidigende, diskriminierende, oder hasserfüllte Kommentare
                werden nicht toleriert.
              </li>
              <li>
                Keine Werbung: <br />
                Unser Gästebuch dient dem Austausch unter Nutzern. <br />
                Werbung oder kommerzielle Beiträge sind nicht erlaubt.
              </li>
              <li>
                Keine unangemessenen Inhalte: <br />
                Veröffentlicht keine vulgären, anstößigen oder gewalttätigen
                Inhalte.
                <br />
                Dazu gehören auch Links zu entsprechenden Seiten.
              </li>
              <li>
                Privatsphäre achten: <br />
                Teilt keine privaten Informationen über euch oder andere
                Personen (z.B. Adressen, Telefonnummern, etc.).
              </li>
              <li>
                Kein Spam: <br />
                Mehrfacheinträge, unnötige Wiederholungen und Spam werden
                gelöscht.
              </li>
              <li>
                Konstruktive Kritik: <br />
                Wir freuen uns über konstruktive Anregungen und Kritik. <br />
                Bitte formuliert diese sachlich und respektvoll.
                <br />
                Rechtliches: <br />
                Beiträge, die gegen geltendes Recht verstoßen,
                <br />
                werden umgehend entfernt und können zu rechtlichen Schritten
                führen.
              </li>
            </ol>
          </Card.Body>
        </Card>

        <div className="guestbook-form">
          <h1>Neuer Gästebuch Eintrag</h1>
          <Form onSubmit={handleSubmit} className="card-form">
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form.Group controlId="formTitle">
              <Form.Label>Titel</Form.Label>
              <Form.Control
                type="text"
                placeholder="Gib deinem Eintrag einen Titel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Nachricht</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Schreibe deine Nachricht"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formAuthor" className="mt-3">
              <Form.Label>Dein Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dein Name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </Form.Group>
            <div className="btn-form-field">
              <Button className="mt-3" variant="primary" type="submit">
                Eintrag hinzufügen
              </Button>
            </div>
          </Form>
        </div>

        {/* Display the guestbook entries */}
        {entries.map((entry, index) => (
          <Card key={index} className="mt-3">
            <Card.Header>{entry.title}</Card.Header>
            <Card.Body>
              <Card.Text>{entry.message}</Card.Text>
              <small>Autor: {entry.author}</small>
              <br />
              <small>Datum: {new Date(entry.createdAt).toLocaleString()}</small>
            </Card.Body>
          </Card>
        ))}

        {/* Load more button */}
        {hasMore && entries.length > 0 && (
          <Button onClick={loadMore}>Mehr anzeigen</Button>
        )}
      </Container>
    </div>
  );
}

export default GuestBook;
