import React from "react";

import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect";

function DonationBoard() {
  useNavigationEffect();
  return (
    <div id="project-donationboard" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Spendentafel</Card.Header>
          <Card.Body>
            <Card.Text>
              Um die nicht geringen Restaurationskosten innerhalb des
              Instandsetzung-Projektes der Hohenfels-Anlagen realisieren zu
              können, <br />
              ist eine Spendentafel angedacht. Diese soll alle Spender
              (namentlich erfasst) öffentlich am Hohenfels wiedergeben.
              <br />
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default DonationBoard;
