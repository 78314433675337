import React from "react";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

function Position() {
  useNavigationEffect();
  return (
    <div id="ruin-position">
      <Container className="main-container">
        <CardWithImageComponent
          id="lage-01"
          className="card-with-image"
          image="../images/general_images/lage-1.png"
          header="Lage der Burg"
          content={
            <div>
              Geographische Lage des ehemaligen Schloss Hohenfels. <br />
              Farblich hervorgehoben die Ausmaße der ehemaligen Cent Dautphe in
              denen die „von Hohenfels“ die Gerichtsgewalt innehatten.
            </div>
          }
        />
        <CardWithImageComponent
          id="karte-schloss-hohenfels"
          className="card-with-image"
          image="../images/general_images/karte-schloss-hohenfels.jpg"
          header="Karte Schloss Hohenfels"
        />
      </Container>
    </div>
  );
}

export default Position;
