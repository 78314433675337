import React from "react";

import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

function EntranceSportField () {
  useNavigationEffect();
  return (
    <div id="project-cellar" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Eingang Buchennau</Card.Header>
          <Card.Body>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </Card.Body>
        </Card>

        <CardWithImageComponent
          id="current-picture"
          className="card-with-image"
          image="../../images/general_images/ruine-einsturz.jpg"
          content={
            <div>
              Some text... <br />
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </div>
          }
        />
      </Container>
    </div>
  );
}

export default EntranceSportField;
