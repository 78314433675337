import React from "react";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

function Visualization() {
  useNavigationEffect();
  return (
    <div id="ruin-visualization" className="webpage">
      <Container className="main-container">
        <Card>
          <Card.Header>Rekonstruktions-Modelle</Card.Header>
          <Card.Body>
            Auf dieser Seite finden sich einige Zeichnung zu den Burganlagen.
          </Card.Body>
        </Card>
        <CardWithImageComponent
          id="modelle-01"
          className="card-with-image"
          image="../images/general_images/modelle-01.png"
          content={<div>1850</div>}
        />
        <CardWithImageComponent
          id="modelle-02"
          className="card-with-image"
          image="../images/general_images/modelle-02.png"
          content={<div>2002/2003</div>}
        />
        <CardWithImageComponent
          id="modelle-03"
          className="card-with-image"
          image="../images/general_images/modelle-03.png"
          content={<div>2002: Hohenfels Westburg</div>}
        />
        <CardWithImageComponent
          id="modelle-04"
          className="card-with-image"
          image="../images/general_images/modelle-04.png"
          content={<div>2024: Hohenfels Ostburg</div>}
        />
      </Container>
    </div>
  );
}

export default Visualization;
