import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";
function RuinImages() {
  const [images, setImages] = useState([]);
  useEffect(function () {
    async function fetchImages() {
      try {
        const response = await axios.post(
          "https://burgverein-hohenfels.de/images/ruin"
        );
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);
  useNavigationEffect();
  return (
    <div id="ruin-gallery" className="webpage">
      <Container className="main-container">
        <CardWithImageComponent
          id="current-picture"
          className="card-with-image"
          image="../images/general_images/ruine-einsturz.jpg"
          header="Bilder der Burgruine"
          content="Einblick in den derzeitigen Zustand des Kellergewölbes: ein Mittelteil der Gewölbedecke ist eingestürzt."
        />
        <div id="images">
          {images.map((imageUrl, index) => (
            <CardWithImageComponent
              id={"ruin_images-" + index}
              image={imageUrl}
              className="card-with-image"
            />
          ))}
        </div>
      </Container>
    </div>
  );
}

export default RuinImages;
