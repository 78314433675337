import React from "react";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../effects/NavigationEffect.js";
import CardWithImageComponent from "../components/CardWithImageComponent.js";

function Home() {
  useNavigationEffect();
  return (
    <div id="home" className="webpage">
      <div id="small-header">
        <h1>Burgverein Hohenfels e.V.</h1>
        <h3>Willkommen auf unserer Website</h3>
      </div>
      <Container>
        <CardWithImageComponent
          id="logo"
          className="card-with-image"
          header="Unser Vereinslogo"
          image="/images/general_images/logo-transparent.png"
          text={<a href="/verein/logo">»Zur Beschreibung«</a>}
        />
        <Card id="upcoming-events">
          <div className="ribbon">
            <div className="ribbon-stitches-top"></div>
            <div className="ribbon-content">
              <p>
                <b>»Höret, was da kommet«</b>
              </p>
            </div>
            <div className="ribbon-stitches-bottom"></div>
          </div>
          <Card.Header className="small-h1">
            <a href="/freizeit/sonderveranstaltungen">Aktuelle Termine</a>
          </Card.Header>
          <Card.Body>
            <ul>
              <li>15. November 2024 → Fachvortrag 850 Jahre Hohenfels</li>
            </ul>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Home;
