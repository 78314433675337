import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

class NavigationBar extends React.Component {
  render() {
    return (
      <Navbar bg="dark" variant="dark" expand="xxl" fixed="top">
        <Navbar.Brand href="/">Burgverein Hohenfels e.V.</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Startseite</Nav.Link>
            <NavDropdown title="Die Ruinen" id="basic-nav-dropdown">
              <NavDropdown.Item href="/ruinen/geschichte">
                Geschichte
              </NavDropdown.Item>
              <NavDropdown.Item href="/ruinen/lage">Lage</NavDropdown.Item>
              <NavDropdown.Item href="/ruinen/rekonstruktions-modelle">
                Rekonstruktions-Modelle
              </NavDropdown.Item>
              <NavDropdown.Item href="/ruinen/bilder">Bilder</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Wer wir sind" id="basic-nav-dropdown">
              <NavDropdown.Item href="/verein/historie">
                Vereinshistorie
              </NavDropdown.Item>
              <NavDropdown.Item href="/verein/aufgaben">
                Aufgaben
              </NavDropdown.Item>
              <NavDropdown.Item href="/verein/vorstand">
                Vorstand
              </NavDropdown.Item>
              <NavDropdown.Item href="/verein/satzung">
                Satzung
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/verein/logo">
                Vereinslogo
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Aktuelle Projekte" id="basic-nav-dropdown">
              <NavDropdown.Item href="/projekte/keller">
                Kellergewölbe
              </NavDropdown.Item>
              <NavDropdown.Item href="/projekte/modell">
                Modell-Erstellung
              </NavDropdown.Item>
              <NavDropdown.Item href="/projekte/spendentafel">
                Spendentafel
              </NavDropdown.Item>
              <NavDropdown.Item href="/projekte/lehrpfad">
                Lehrpfad
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/gaestebuch">Gästebuch</Nav.Link>
            <Nav.Link href="/mitglied-werden">Mitglied werden</Nav.Link>
            <Nav.Link href="/impressum">Impressum</Nav.Link>
            <Nav.Link href="/datenschutz">Datenschutz</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavigationBar;
