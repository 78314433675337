import React from "react";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
function Tasks() {
  useNavigationEffect();

  return (
    <div id="tasks" className="webpage">
      <Container className="main-container">
        <Card id="tasks-card">
          <Card.Header>Aufgaben</Card.Header>
          <Card.Body>
            Auszug aus unserer aktuellen Satzung (§2 gültig seit 1997): <br />
            §2 Vereinszweck
            <br />
            Zweck des Vereins ist die Förderung, Pflege und <br />
            Vermittlung der Geschichte des Geschlechts derer von Hohenfels{" "}
            <br />
            als herausragende Repräsentanten der mittelalterlich-feudalen
            Gesellschaft im Raum Marburg-Biedenkopf.
            <br />
            Der Satzungszweck wird verwirklicht insbesondere durch:
            <br />
            1&#41; Erforschung, Sicherung und Pflege des geschichtlich
            bedeutsamen Denkmals der Hohenfelser, der Doppelburg Hohenfels{" "}
            <br />
            2&#41; Erforschung der Geschichte derer von Hohenfels <br />
            3&#41; Beschreibung und Vermittlung mittelalterlich-feudaler
            Gesellschaftsstrukturen und ihre Konkretisierung im Alltagsleben der
            Hinterländer Bevölkerung.
            <br />
            4&#41; Vermittlung der geschichtlichen Inhalte und historische
            Objekte an die interessierte Öffentlichkeit (Jugendliche, heimische
            Bevölkerung, Touristen).
            <br />
            <br />
            Punkt 1 hat für unseren Verein derzeit oberste Priorität: <br />
            In den letzten Jahren konnte der Einsturz großer Teile des
            Kellergewölbes nicht verhindert werden. <br />
            Der Verein strengt derzeit die Restauration des Kellergewölbes an,
            wirbt dafür bei zahlreichen Veranstaltungen für Unterstützung in
            Form von Spenden und Mitgliedschaften. <br />
            <a href="/projekte/keller"> Mehr zum Projekt: Kellergewölbe</a>
            <br />
            <br />
            Außerdem betreiben wir ein kleines Hohenfels-Museum. <br />
            Wir stehen generell für den persönlichen Kontakt und bieten
            Praxis-Veranschaulichung vor Ort in Form von geführten Wanderungen
            zu den Ruinen an oder-/und dem Besuch des Hohenfels-Museums. Im
            Museum können Ausgrabungsfunde besichtigt und erklärt werden. Fotos
            und Anschauungstafeln runden das Angebot des Museums ab.
            <br />
            Nach dem Motto „Ohne Nutz kein Schutz“ arbeiten wir verstärkt auf
            die Einbindung des Hohenfels-Areals als Veranstaltungsort für
            Kultur, Natur und Geschichte hin. Nahezu das gesamte Gelände der
            beiden Ruinen strahlt eine einmalige, immer unterschiedlich
            nuancierte Atmosphäre aus.
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Tasks;
