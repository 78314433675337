import React from "react";
import { Card, Modal, Button } from "react-bootstrap";
//import './CardComponent.scss';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleImageClick() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { id, className, image, header, title, text, content, footer } =
      this.props;
    const { showModal } = this.state;

    return (
      <div id={id} className={className}>
        <Card className="card-with-image">
          <Card.Header>{header}</Card.Header>
          <Card.Img
            variant="center"
            src={image}
            onClick={this.handleImageClick}
          />

          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text className="card-text">{text}</Card.Text>
            {content}
          </Card.Body>
          <Card.Footer>{footer}</Card.Footer>
        </Card>
        <Modal
          show={showModal}
          onHide={this.handleCloseModal}
          size="xl"
          centered
          className="modal-card"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={image} alt={title} className="img-fluid" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CardComponent;
